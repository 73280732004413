import React from "react";
import styled from "styled-components";
import Flex from "components/Flex";

const StyledComponent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	max-width: 1200px;
	min-height: ${(props) => props.minHeight || "100vh"};
	padding: 6rem;
	border-bottom: 1px solid var(--lines-color);

	@media (max-width: 768px) {
		padding: 2rem;
	}
`;

const Page = ({
	id, top, middle, bottom, ...rest
}) => (
	<StyledComponent {...rest} id={id}>
		<Flex>
			{top}
		</Flex>
		<Flex>
			{middle}
		</Flex>
		<Flex>
			{bottom}
		</Flex>
	</StyledComponent>
);

Page.defaultProps = {};
Page.propTypes = {};

export default Page;
