import React from "react";
import styled from "styled-components";

const StyledComponent = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "var(--headline-dark)")};
`;

const ColourSection = ({ children, ...rest }) => (
	<StyledComponent {...rest}>
		{children}
	</StyledComponent>
);

export default ColourSection;
