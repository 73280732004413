import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
	cursor: pointer;
	height: 32px;
	width: 32px;

	.bounds {
		fill: none;
	}

	path {
		fill: none;
		stroke: var(--headline-dark);
		stroke-linecap: round;
		stroke-miterlimit: 10;
		stroke-width: 3px;
	}
`;

const CloseIcon = () => (
	<StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
		<rect className="bounds" width="32" height="32" />
		<path d="M-43.44-1098.9l24,20.1" transform="translate(47.38 1104.85)" />
		<path d="M-21.39-1100.85l-20.1,24" transform="translate(47.38 1104.85)" />
	</StyledSvg>
);

export default CloseIcon;
