import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Page from "components/Page";
import Columns from "components/Columns";
import ColourSection from "components/ColourSection";
import ExternalLink from "./ExternalLink";

const StyledComponent = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

const Footer = () => (
	<StyledComponent>
		<ColourSection backgroundColor="var(--accent-color-light)">
			<Page
				top={null}
				middle={(
					<Columns data-sal="slide-up">
						<div className="column">
							<h4>Site index</h4>
							<p>
								<Link to="/">Home</Link>
								{" "}
								<br />
								<Link to="/work">Work</Link>
								{" "}
								<br />
								<Link to="/services">Services</Link>
								{" "}
								<br />
								<Link to="/contact">Contact</Link>
							</p>
						</div>
						<div className="column">
							<h4>Follow us</h4>
							<p>
								<ExternalLink to="https://www.facebook.com/pages/Mothership-Software-Limited/137558212939278">Facebook</ExternalLink>
								{" "}
								<br />
								<ExternalLink to="https://twitter.com/mothershipsoft">Twitter</ExternalLink>
								{" "}
								<br />
								<ExternalLink to="https://www.youtube.com/user/MothershipSoftware">YouTube</ExternalLink>
								{" "}
								<br />
							</p>
						</div>
						<div className="column">
							<h4>contact us</h4>
							<p>
								<ExternalLink to="mailto:info@wearemothership.com">info@wearemothership.com</ExternalLink>
								{" "}
								<br />
								<ExternalLink to="mailto:tim@wearemothership.com">tim@wearemothership.com</ExternalLink>
								{" "}
								<br />
								<ExternalLink to="tel:+44-1252-622800">call: (44) 1252 622800</ExternalLink>
								{" "}
								<br />
							</p>
						</div>
					</Columns>
				)}
				bottom={(
					<p>
						<small>
							© 2021 Mothership Software Limited. Mothership Software Limited is
							a company registered in England and Wales with company number
							7204964. Registered office: Wey Court West, Union Road. Farnham.
							Surrey. GU9 7PT. United Kingdom.
							{" "}
							<span role="img" aria-label="Covid Safe">😷 ✌️</span>
						</small>
					</p>
				)}
			/>
		</ColourSection>
	</StyledComponent>
);

Footer.defaultProps = {};
Footer.propTypes = {};

export default Footer;
