import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
	cursor: pointer;
	height: 32px;
	width: 32px;

	.bounds {
		fill: none;
	}

	path {
		fill: none;
		stroke: var(--headline-dark);
		stroke-linecap: round;
		stroke-miterlimit: 10;
		stroke-width: 3px;
	}
`;

const MenuIcon = (props) => (
	<StyledSvg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
		<rect className="bounds" width="32" height="32" />
		<path d="M-72-1076.85H-96" transform="translate(99.92 1104.85)" />
		<path d="M-96-1088.85h24" transform="translate(99.92 1104.85)" />
		<path d="M-96-1100.85h24" transform="translate(99.92 1104.85)" />
	</StyledSvg>
);

MenuIcon.defaultProps = {};
MenuIcon.propTypes = {};

export default MenuIcon;
