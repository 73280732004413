import React from "react";
import styled from "styled-components";
// original size is 60x60px

const StyledSvg = styled.svg`
	cursor: pointer;
	height: ${(props) => (props.fluid ? "auto" : "60px")};
	width: ${(props) => (props.fluid ? "100%" : "60px")};

	&:hover {
		path {
			stroke: var(--accent-color) !important;
		}
	}

	.bounds {
		fill: none;
	}

	path {
		fill: none;
		stroke: var(--headline-dark);
		stroke-linecap: round;
		stroke-miterlimit: 10;
		stroke-width: 4px;
		stroke-dasharray: 200;
		stroke-dashoffset: 200;
		animation: draw 1.5s ease forwards;
		transition: stroke 0.25s ease;
	}

	#accentPath {
		stroke: var(--accent-color);
		animation-delay: 0.25s;
	}

	#mainPath2 {
		stroke: var(--headline-dark);
		animation-delay: 0.5s;
	}

	@keyframes draw {
		to {
			stroke-dashoffset: 0;
		}
	}
`;

const Logo = (props) => (
	<StyledSvg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
		<rect className="bounds" width="60" height="60" />
		<path id="mainPath" d="M3,48V30a18,18,0,0,1,36,0v9a9,9,0,0,1-18,0V30a18,18,0,0,1,36,0V48" />
		<path id="accentPath" d="M3,48V30a18,18,0,0,1,36,0v9a9,9,0,0,1-18,0V30a18,18,0,0,1,36,0V48" />
		<path id="mainPath2" d="M3,48V30a18,18,0,0,1,36,0v9a9,9,0,0,1-18,0V30a18,18,0,0,1,36,0V48" />
	</StyledSvg>

);

Logo.defaultProps = {};
Logo.propTypes = {};

export default Logo;

// <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><defs><style>.cls-1,.cls-2{fill:none;}.cls-2{stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:4px;}</style></defs><rect class="cls-1" width="60" height="60"/><path class="cls-2" d="M3,48V30a18,18,0,0,1,36,0v9a9,9,0,0,1-18,0V30a18,18,0,0,1,36,0V48"/></svg>
