import React from "react";
import styled from "styled-components";

const StyledComponent = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    flex-direction: ${(props) => props.flexDirection || "column"};
    flex-grow: ${(props) => props.flexGrow || 0};
    flex-basis: ${(props) => props.flexBasis || "auto"};
    flex-shrink: ${(props) => props.flexShrink || 1};
    flex-wrap: ${(props) => props.flexWrap || "nowrap"};
    flex: ${(props) => props.flex || "0 1 auto"};
    align-items: ${(props) => props.alignItems || "stretch"};
    margin: ${(props) => props.margin || "0"};
    margin-bottom: ${(props) => props.marginBottom || "0"};
    width: ${(props) => props.width || "auto"};
    height: ${(props) => props.height || "initial"};
    max-width: ${(props) => props.maxWidth || "none"};
    min-height: ${(props) => props.minHeight || "none"};
    border: ${(props) => props.border || "none"};
    border-top: ${(props) => props.borderTop || "none"};
    border-bottom: ${(props) => props.borderBottom || "none"};
`;

const Flex = ({ children, ...rest }) => (
	<StyledComponent {...rest}>
		{children}
	</StyledComponent>
);

export default Flex;
