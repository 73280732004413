import React from "react";
import styled from "styled-components";
import Flex from "components/Flex";

const StyledComponent = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	border-radius: 0.5rem;
	background-image: ${(props) => (props.backgroundImage ? `url(${props.backgroundImage})` : null)};
	background-size: cover;
	background-position: center;

	.column {
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;
		padding-right: 3rem;
		flex:1;

		@media (max-width: 768px) {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	.column:last-of-type {
		padding-right: 0rem;
	}

	@media (max-width: 768px) {
		div:first-of-type {
			flex-direction: column;
		}

		.column:first-of-type, column {
			padding: 0;
		}
	}
`;

const Columns = ({ children }) => (
	<StyledComponent>
		<Flex flexDirection="row" padding="0" width="100%" height="100%">
			{children}
		</Flex>
	</StyledComponent>
);

export default Columns;
